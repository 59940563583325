import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { useNavigate } from '@reach/router';

const useStyles = makeStyles({
  root: {
    height: '500px',
  },
  content: {
    height: '300px',
  },
});

export default function BlogCard(props) {
  const classes = useStyles();
  const {
    heading, body, href, featuredImgFluid,
  } = props;
  const navigate = useNavigate();

  const handleRoute = (loc) => {
    navigate(loc);
  };

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <Img fluid={featuredImgFluid} style={{ maxHeight: 150 }} />
        <CardContent className={classes.content}>
          <Typography gutterBottom variant="h5" component="h2">
            {heading}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p" style={{ textAlign: 'justify' }}>
            {body}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions style={{ float: 'right' }}>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          onClick={() => handleRoute(href)}
        >
          Read More
        </Button>
      </CardActions>
    </Card>
  );
}

BlogCard.propTypes = {
  heading: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  featuredImgFluid: PropTypes.any.isRequired,
};
