/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import { StaticQuery, graphql } from 'gatsby';
import Header from '../Header';
import Typography from '../Typography';
import LinkList from './link-list';
import BlogCard from './blog-card';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    overflow: 'hidden',
  },
  grid: {
    display: 'flex',
    // overflow: 'hidden',
    flexGrow: 1,
    // justifyContent: 'space-around',
    paddingBottom: '15px',
    margin: '20px',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: '50px',
    padding: '5px',
  },
  section: {
    maxWidth: '100vw',
    display: 'flex',
  },
  title: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  cta: {
    marginTop: 24,
    textTransform: 'initial',
  },
  header: {
    marginTop: '70px',
  },
  info: {
    padding: '50px',
  },
  content: {
    margin: '25px',
  },
  about: {
    paddingRight: '30px',
  },
  value: {
    padding: '20px',
  },
});

const linkData = [
  {
    key: 'direct',
    name: 'Direct Taxes',
    data: [
      {
        name: 'Income Tax of India',
        href: 'https://www.incometaxindia.gov.in',
      },
      {
        name: 'Tax Information Network-NSDL',
        href: 'https://www.tin-nsdl.com/',
      },
      {
        name: 'Income Tax E-Filling',
        href: 'https://www.incometaxindiaefiling.gov.in/',
      },
      {
        name: 'National Academy of Direct Taxes',
        href: 'http://nadt.gov.in',
      },
      {
        name: 'Indian Revenue Services',
        href: 'http://irsofficersonline.gov.in/',
      },
      {
        name: 'TDS Centralised Processing Cell',
        href: 'https://tdscpc.gov.in/',
      },
    ],
  },
  {
    key: 'indirect',
    name: 'Indirect Taxes',
    data: [
      {
        name: 'Goods and Service Tax – GST',
        href: 'https://www.gst.gov.in/',
      },
      {
        name: 'Ministry of Finance – CBEC',
        href: 'https://aces.gov.in/',
      },
      {
        name: 'Tax Directorate General of Foreign Trade',
        href: 'http://dgft.gov.in/',
      },
      {
        name: 'E-payment Portal for Excise & Service Tax',
        href: 'http://cbec.nsdl.com/',
      },
      {
        name: 'ICE-Gate Portal – CBEC',
        href: 'https://www.icegate.gov.in/',
      },
      {
        name: 'Karnataka VAT',
        href: 'http://ctax.kar.nic.in/',
      },
      {
        name: 'E – payment/filing portal for Karnataka VAT',
        href: 'http://vat.kar.nic.in/',
      },
    ],
  },
  {
    key: 'corporate',
    name: 'Corporate and other Allied Laws',
    data: [
      {
        name: 'Ministry of Corporate Affairs',
        href: 'http://mca.gov.in/',
      },
      {
        name: 'Karnataka Professional Tax',
        href: 'https://pt.kar.nic.in',
      },
      {
        name: 'Employee’s Provident Fund',
        href: 'http://epfindia.gov.in/',
      },
      {
        name: 'Employee’s State Insurance Corporation',
        href: 'http://esic.in/',
      },
      {
        name: 'Ministry of Labour and Employment',
        href: 'https://shramsuvidha.gov.in/home',
      },
      {
        name: 'MSME',
        href: 'https://msme.gov.in/',
      },
    ],
  },
  {
    key: 'others',
    name: 'Others',
    data: [
      {
        name: 'Central Vigilance Commission',
        href: 'https://cvc.nic.in/',
      },
      {
        name: 'Reserve Bank of India',
        href: 'https://www.rbi.org.in/',
      },
      {
        name: 'The Institute of Chartered Accountants of India',
        href: 'https://icai.org/',
      },
      {
        name: 'The Institute of Cost Accountants of India',
        href: 'https://icmai.in/icmai/',
      },
      {
        name: 'The Institute of Company Secretaries of India',
        href: 'https://icsi.edu/home/',
      },
    ],
  },
];

const Links = ({
  data, // this prop will be injected by the GraphQL query below.
}) => {
  const classes = useStyles();
  const posts = data.allMdx.nodes;

  return (
    <section style={{ backgroundColor: '#ffffff' }}>
      <Header />
      <Paper elevation={0}>
        <section>
          <Grid container className={classes.header} spacing={2}>
            <Grid key={1} item xs={12} md={12}>
              <Typography
                variant="h3"
                align="center"
                marked="center"
                style={{ paddingTop: '20px' }}
              >
                Useful Links
              </Typography>
            </Grid>
          </Grid>
        </section>
        <CardContent className={classes.content}>
          <LinkList data={linkData} />
        </CardContent>
      </Paper>
      <Typography
        variant="h3"
        align="center"
        marked="center"
        style={{ paddingTop: '20px' }}
      >
        Blogs
      </Typography>
      <br />
      <section className={classes.section}>
        <Grid container justify="center" className={classes.grid} spacing={4}>
          {posts.map((postData) => {
            const {
              title, featuredImage, desc, path,
            } = postData.frontmatter;
            return (
              <Grid key={title} item xs={12} md={3}>
                <BlogCard
                  heading={title}
                  body={desc}
                  href={path}
                  featuredImgFluid={featuredImage.childImageSharp.fluid}
                />
              </Grid>
            );
          })}
        </Grid>
      </section>
    </section>
  );
};

export default () => (
  <StaticQuery
    query={graphql`
      query {
        allMdx(sort: {order: DESC, fields: frontmatter___id}) {
          nodes {
            frontmatter {
              date
              title
              desc
              path
              id
              featuredImage {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => <Links data={data} />}
  />
);
