import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import PropTypes from 'prop-types';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '../Typography';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  heading: {
    margin: 'auto',
  },
}));

export default function LinkList(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const { data } = props;

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.root}>
      {data.map((service) => (
        <ExpansionPanel
          key={service.key}
          expanded={expanded === service.key}
          onChange={handleChange(service.key)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography className={classes.heading} variant="h6" align="center">
              {service.name}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <List>
              {service.data.map((link) => (
                <a href={link.href} target="_blank" rel="noopener noreferrer">
                  <ListItem key={link.name}>
                    <LabelImportantIcon />
                    <ListItemText primary={link.name} />
                  </ListItem>
                </a>
              ))}
            </List>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      ))}
    </div>
  );
}

LinkList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array.isRequired,
};
